import $ from "jquery";

$('body').on('change', 'input[name="approved_geos"]', function(e) {
  const geos = $(this).val().split(' ');
  const approvedGeosSelector = $('.approved-geos-select2');

  approvedGeosSelector.empty();
  approvedGeosSelector.val(null)
  const isPreselectItems = !$(this).data('emptyList')

  geos.forEach(function(geo) {
    var newOption = new Option(geo, geo, true, isPreselectItems);
    approvedGeosSelector.append(newOption);
  });

  if (geos.length === 1 && geos[0] === 'all') {
    approvedGeosSelector.parent().addClass('invisible');
  } else {
    approvedGeosSelector.parent().removeClass('invisible');
  }
  approvedGeosSelector.trigger('change');
});
