import $ from 'jquery';
import 'bootstrap/js/dist/popover';

function preInitXEditable() {
  if ($('.x-editable-card').length) {
    import('x-editable-bs4/dist/bootstrap4-editable/css/bootstrap-editable.css').then(() => {
      import('x-editable-bs4/dist/bootstrap4-editable/js/bootstrap-editable.min.js').then(() => {
        initXEditable();
      });
    });
  }
}

function editableParams(params) {
  params.authenticity_token = $('[name="csrf-token"]')[0].content;
  const ptParams = {};
  ptParams[params.name] = params.value;
  params.comment = ptParams;
  return params;
}

function initXEditable() {
    if (!$.fn.editable) return

    // Font Awesome support
    $.fn.editableform.buttons =
        '<button type="submit" class="btn btn-primary btn-sm editable-submit">' +
        '<i class="fa fa-fw fa-check"></i>' +
        '</button>' +
        '<button type="button" class="btn btn-default btn-sm editable-cancel">' +
        '<i class="fa fa-fw fa-times"></i>' +
        '</button>';

    //defaults
    $.fn.editable.defaults.ajaxOptions = { type: "PUT" };

    $('.comment-content').editable({
      mode: 'inline',
      inputclass: 'comment_content_textarea',
      params: (params) => { return editableParams(params) },
      success: (response, _newValue) => {
        $(`#comment-${response.id} .comment-updated-at`).text(response.updated_at);
      }
    });
}

$(function() {
    window.commentPreInitXEditable = preInitXEditable;
    window.commentPreInitXEditable();
});
